import { Grid } from "@mui/material";
import { CarouselItem } from "./CarrouselItem";
import "./slideParceiros.scss";

const images = [
  {
    label: "Crediagora",
    imgPath: "/logo-crediagora.png",
  },
  {
    label: "Credisa",
    imgPath: "/logo-credisa.png",
  },
  {
    label: "Grupo-BG",
    imgPath: "/logo-grupo-bg.png",
  },
  {
    label: "Potenza",
    imgPath: "/logo-potenza.png",
  },
  {
    label: "Crediajat",
    imgPath: "/logo-crediajat.png",
  },
  {
    label: "Credi Show",
    imgPath: "/logo-credi-show.png",
  },
  {
    label: "Lopes Cred",
    imgPath: "/logo-lopes-cred.png",
  },
  {
    label: "Vida Boa Empréstimos",
    imgPath: "/logo-vida-boa.png",
  },
];

export default function SliderPartner() {
  return (
    <Grid className="carousel-container">
      <Grid className="carousel-track">
        {images.map((detailKey) => {
          return (
            <CarouselItem
              imgUrl={detailKey.imgPath}
              imgTitle={detailKey.label}
            />
          );
        })}
        {images.map((detailKey) => {
          return (
            <CarouselItem
              imgUrl={detailKey.imgPath}
              imgTitle={detailKey.label}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
