import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "./index.scss";

function Rodape() {
  const navigation = useNavigate();

  return (
    <Container className="sessao-rodape" component={"footer"}>
      <Grid
        container
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {isMobile && (
          <Grid item xs={12} md={6}>
            <Grid className="imagem">
              <img src="/logo_rodape.png" alt="Logo Quita Pay" />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Grid
            container
            spacing={{ xs: 3, sm: 1 }}
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send?phone=555599042292&text=Ol%C3%A1. gostaria de me tornar um parceiro!`
              );
            }}>
            <Grid item xs={1} className="icons-redes-sociais">
              <WhatsAppIcon className="icon" />
            </Grid>

            <Grid item xs={11}>
              <Typography className="contato">(55) 9904-2292</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 3, sm: 1 }}>
            <Grid item xs={1} className="icons-redes-sociais">
              <MailOutlineIcon className="icon" />
            </Grid>

            <Grid item xs={11}>
              <Typography className="contato">ola@quitapay.com.br</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 3, sm: 1 }}>
            <Grid item xs={1} className="icons-redes-sociais">
              <LocationOnOutlinedIcon className="icon" />
            </Grid>

            <Grid item xs={11}>
              <Typography className="endereco">Rua Dahne de Abreu, 2700 - Distrito Industrial 2</Typography>
              <Typography className="endereco">
                Horizontina RS | Brasil CEP 98920-000
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {!isMobile && (
          <Grid item xs={12} sm={6} md={4}>
            <Grid className="imagem">
              <img src="/logo_rodape.png" alt="Logo Quita Pay" />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4} className="links">
          <Grid className="infos-quita">
            <Typography
              className="texto"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/quita-pay/",
                  "_blank"
                )
              }>
              LINKEDIN
            </Typography>
            <Typography>|</Typography>
            <Typography
              className="texto"
              onClick={() =>
                window.open("https://www.instagram.com/quita.pay/", "_blank")
              }>
              INSTAGRAM
            </Typography>
          </Grid>
          <Grid className="infos-quita">
            <Typography
              className="texto"
              onClick={() =>
                window.open("https://www.youtube.com/@QuitaPayCanal", "_blank")
              }>
              YOUTUBE
            </Typography>
            <Typography>|</Typography>
            <Typography
              className="texto"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=61556968066419",
                  "_blank"
                )
              }>
              FACEBOOK
            </Typography>
          </Grid>
          <Grid className="infos-quita">
            <Typography
              className="texto"
              onClick={() => {
                navigation("/termos-de-uso");
              }}>
              TERMOS DE USO
            </Typography>
          </Grid>
          <Grid className="infos-quita">
            <Typography
              className="texto"
              onClick={() =>
                window.open("/politica-privacidade.html", "_blank")
              }>
              POLÍTICA DE PRIVACIDADE
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography>
            QUITA PAY - TODOS OS DIREITOS RESERVADOS &copy; | CNPJ:
            49.595.786/0001-26
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Rodape;
