import { Container, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import Rodape from "@/components/rodape";
import BotaoCadastro from "@/components/BotaoCadastro";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import "./index.scss";

export default function Desenvolvedores() {
  return (
    <>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Helmet>
          <title>QuitaPay - Integração API</title>
          <link rel="canonical" href="https://quitapay.com" />
          <meta
            name="description"
            content="Integre seu sistema com a nossa API e realize seus parcelamentos."
          />
        </Helmet>

        <Grid container spacing={2} maxWidth={"xl"}>
          <Grid item xs={12} sx={{ p: 1 }}>
            <Typography variant="h1" className="titulo">
              Integre seu sistema com a API da{" "}
              <span className="quita">QuitaPay</span> e realize seus
              parcelamentos de forma simples e segura.
            </Typography>
            <Typography className="texto-secundario" sx={{ pt: 2 }}>
              Disponibilizamos ambientes seguros e devidamente autenticados para
              os nossos parceiros.
            </Typography>
            <Typography
              className="texto-secundario"
              sx={{ pt: { xs: 0, sm: 2 } }}>
              Integrar seu sistema com a <span className="quita">QuitaPay</span>{" "}
              é SIMPLES ASSIM!
            </Typography>

            <BotaoCadastro
              className="criar-conta"
              sx={{ mt: 2, pr: 2, pl: 2, display: "block" }}>
              QUERO ABRIR A MINHA CONTA
            </BotaoCadastro>
            <BotaoPrincipal
              className="botao-documentacao"
              onClick={() => {
                window.open("https://quitapay.docs.apiary.io/#", "_blank");
              }}>
              ACESSAR DOCUMENTAÇÃO
            </BotaoPrincipal>
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={2} maxWidth={"xl"}>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ display: { xs: "none", lg: "flex" } }}>
            <img
              loading="lazy"
              className="img-banner"
              src="/ambiente-sandbox.webp"
              alt="sandbox"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo">
              Ambiente sandbox: área segura para realizar seus testes
            </Typography>
            <Typography className="texto-secundario">
              Utilize a nossa área de testes para garantir o funcionamento de
              todas as suas integrações.
            </Typography>

            <BotaoPrincipal
              className="botao-documentacao"
              onClick={() => {
                window.open(
                  "https://quitapay.docs.apiary.io/#introduction/ambiente-de-testes-e-producao",
                  "_blank"
                );
              }}>
              ACESSAR DOCUMENTAÇÃO
            </BotaoPrincipal>
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={2} maxWidth={"xl"}>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo">
              Webhook: respostas em tempo real
            </Typography>
            <Typography className="texto-secundario">
              Automatize respostas para que você receba uma atualização do seu
              parcelamento quando ele for finalizado, por exemplo.
            </Typography>

            <BotaoPrincipal
              className="botao-documentacao"
              onClick={() => {
                window.open(
                  "https://quitapay.docs.apiary.io/#introduction/webhook",
                  "_blank"
                );
              }}>
              ACESSAR DOCUMENTAÇÃO
            </BotaoPrincipal>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ display: { xs: "none", lg: "flex" }, justifyContent: "end" }}>
            <img
              loading="lazy"
              className="img-banner"
              src="/webhooks.webp"
              alt="webhooks"
            />
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={2}>
          <Grid
            item
            lg={6}
            sx={{
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
            }}>
            <img
              loading="lazy"
              className="img-banner"
              src="/whitalabel-icon.webp"
              alt="whitelabel"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo">
              Whitelabel: crie sua própria solução personalizada
            </Typography>
            <Typography className="texto-secundario">
              Envie links para contratação aos seus clientes com sua logo,
              identidade visual e domínio gerando mais confiança e facilidades
              no dia-a-dia.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={2} maxWidth={"xl"}>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo-collections">
              Acesse nossas collections
            </Typography>
            <Typography className="texto-secundario">
              Disponibilizamos nossas collections no postman para agilizar seus
              testes. Clique no botão abaixo para acessar.
            </Typography>

            <img
              title="Acessar collection postman"
              className="logo-postman"
              height={60}
              width={60}
              src="/logo-postman.png"
              alt="logo postman"
              loading="lazy"
              onClick={() => {
                window.open(
                  "https://app.getpostman.com/run-collection/28280417-434542ad-7759-48df-870c-d27104996732?action=collection%2Ffork&source=rip_markdown&collection-url=entityId%3D28280417-434542ad-7759-48df-870c-d27104996732%26entityType%3Dcollection%26workspaceId%3Da8e50266-4e8c-4d91-a564-22f91b1e07bf",
                  "_blank"
                );
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
}
