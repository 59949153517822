import SiteLayout from "@/layout/SiteLayout";
import Inicio from "@/pages/Inicio";
import TermosUso from "@/pages/TermosUso";
import Desenvolvedores from "@/pages/Desenvolvedores";

const SiteRoutes = {
  path: "/",
  element: <SiteLayout />,
  children: [
    {
      path: "/",
      element: <Inicio />,
    },
    {
      path: "/termos-de-uso",
      element: <TermosUso />,
    },
    {
      path: "/desenvolvedores",
      element: <Desenvolvedores />,
    },
    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default SiteRoutes;
