import { ehHomologacao, ehLocal } from "@/services/api";

export function ehProducao() {
  return process.env.NODE_ENV === "production";
}

export function retornaUrlApp() {
  if (ehLocal()) {
    return "http://localhost:3001";
  }
  if (ehHomologacao()) {
    return "https://app.homologacao.quitapay.com";
  }
  return "https://app.quitapay.com";
}

export function direcionaParaApp(url) {
  if (ehHomologacao()) {
    window.location.href = "https://app.homologacao.quitapay.com" + url;
    return;
  } else if (ehProducao()) {
    return (window.location.href = "https://app.quitapay.com" + url);
  }

  return (window.location.href = "http://localhost:3001" + url);
}
