import { Button } from "@mui/material";

export default function BotaoWhatsapp(props) {
  return (
    <Button
      variant="contained"
      onClick={() => {
        window.open(
          `https://api.whatsapp.com/send?phone=555599042292&text=Ol%C3%A1. gostaria de me tornar um parceiro!`
        );
      }}
      {...props}>
      {props.children}
    </Button>
  );
}
