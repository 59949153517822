import { ArrowDownward, WhatsApp } from "@mui/icons-material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rodape from "@/components/rodape";
import CookieBanner from "@/components/CookieBanner";
import BotaoCadastro from "@/components/BotaoCadastro";
import BotaoWhatsapp from "@/components/BotaoWhatsapp";
import SliderPartner from "../../components/slideParceiros";
import "./index.scss";

const Inicio = () => {
  return (
    <>
      <Container id="inicio" className="sessao-site banner" component="main">
        <CookieBanner />

        <Grid
          container
          spacing={3}
          className="texto-banner"
          sx={{ p: 3, m: "auto" }}
          maxWidth="lg">
          <Grid item xs={12} md={8}>
            <Typography className="titulo" variant="h1">
              Recupere histórias e lucre por isso.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="texto-secundario">
              Ser parceiro Quita Pay é SIMPLES ASSIM.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ xs: { mt: 2 } }}>
            <BotaoCadastro className="botao-verde">FAÇA PARTE</BotaoCadastro>
          </Grid>
        </Grid>
      </Container>
      <Grid className="deslize-mais">
        <Grid className="deslize-mais-text">
          <ArrowDownward className="arrow-icon" />
          <Typography className="text">DESLIZE PARA SABER MAIS</Typography>
        </Grid>
      </Grid>
      <Container className="sessao-site sessao-2" component="section">
        <Grid container maxWidth="xl">
          <Grid item xs={12} lg={6} className="left-items">
            <Typography variant="h1" className="primeiro-texto">
              Todo brasileiro merece ter liberdade financeira
            </Typography>

            <Typography className="descricao">
              Mais que parceira, Quita Pay é o melhor negócio para parcelamentos
              de débitos corporativos ou pessoais.
            </Typography>

            <Typography className="descricao">
              Transparência, praticidade, vantagens exclusivas, suporte e
              criptografia de ponta a ponta, como você e seu negócio merecem.
            </Typography>

            <Grid item xs={12} className="botao">
              <BotaoCadastro className="botao-roxo">
                CONHEÇA AGORA
              </BotaoCadastro>
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
            }}>
            <img src="/imagem-02.png" alt="" />
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-3" component="section">
        <Grid container maxWidth="xl" justifyContent="center">
          <Grid item xs={12} md={4} className="cards">
            <Grid className="card">
              <img src="/layer_1-2.svg" alt="" />

              <Typography className="titulo-card">
                <b>Um terço</b> da população brasileira precisa combater o
                endividamento.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="cards">
            <Grid className="card">
              <img src="/graph-percent.svg" alt="" />

              <Typography className="titulo-card">
                <b>Mais de 70%</b> dos lares brasileiros possuem dívidas.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="cards">
            <Grid className="card">
              <img src="/people.svg" alt="" />

              <Typography className="titulo-card">
                <b>8 a cada 10</b> pessoas possuem problemas financeiros no
                Brasil.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} align="center">
            <Grid xs={12} lg={7}>
              <Typography className="descricao">
                Você e a sua empresa podem ajudar os brasileiros a{" "}
                <b>recuperarem suas histórias</b> enquanto <b>ganham</b> com
                isso.
              </Typography>
            </Grid>
          </Grid>

          <BotaoCadastro className="botao-verde">SAIBA MAIS</BotaoCadastro>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-4" component="section">
        <Typography variant="h1" className="texto-sessao">
          Nossa solução digital é mais fácil do que você imagina!
        </Typography>

        <Typography className="texto-sessao subtitulo">
          Além do parcelamento de <b>Pix</b>, oferecemos também parcelamento de
          diferentes tipos de boletos, como:
        </Typography>

        <Grid item xs={12} className="items-pagamento">
          <Grid className="icon check">
            <img src="/icon-check.svg" alt="" width={50} height={50} />
          </Grid>

          <Grid className="icon asterisk">
            <img src="/icon-asterisk.svg" alt="" height={50} width={50} />
          </Grid>

          <ul className="lista-items">
            <li>Luz, água, telefone;</li>
            <li>Aluguel ou condomínio;</li>
            <li>Mensalidades de faculdades, escolas ou cursos;</li>
            <li>Plano de saúde;</li>
            <li>Impostos, IPTU ou IPVA;</li>
            <li>E muito mais...</li>
          </ul>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-simulacao" component="section">
        <Grid container maxWidth="xl" justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Typography variant="h1" className="titulo">
              Liberdade para você possibilitar seu cliente parcelar PIX e
              boletos no <b>cartão de crédito</b>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="subtitulo">
              Com a Quita Pay, é SIMPLES ASSIM.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site seja-parceiro" component="section">
        <Grid container maxWidth="xl" className="container-sessao">
          <Grid item xs={12} md={2} sx={{ pr: 1 }}>
            <Typography variant="h1" className="titulo-left">
              SEJA PARCEIRO
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ pr: 1 }}>
            <Typography
              variant="h3"
              sx={{
                textAlign: { xs: "center", lg: "left" },
                fontSize: "2rem",
                pl: 2,
              }}>
              O melhor negócio para a sua empresa.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography className="subtitulo">
              Oferecemos o parcelamento de boletos e Pix para os seus clientes,
              e você recebe uma comissão atrativa por cada transação realizada.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography className="titulo-cards">
              Como você e sua empresa ganham com isso?
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} className="cards">
            <Grid className="card verde">
              <Typography variant="h1" className="titulo-card">
                Comissão por operação
              </Typography>
              <Typography className="descricao-card">
                O sistema apresenta em tempo real o comissionamento gerado por
                operação finalizada.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="cards">
            <Grid className="card verde">
              <Typography variant="h1" className="titulo-card">
                Sub comissionamento
              </Typography>
              <Typography className="descricao-card">
                É possível cadastrar parceiros abaixo do seu código, gerando
                parceiros substabelecidos.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="cards">
            <Grid className="card verde">
              <Typography variant="h1" className="titulo-card">
                Indicação de parceiros
              </Typography>
              <Typography className="descricao-card">
                Indique outras empresas para serem nossos parceiros e receba
                comissionamento por todas as vendas realizadas.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="texto-rodape-sessao">
            <Typography className="texto">
              <b>Potencialize seus lucros</b> com a QuitaPay. Parceria de
              verdade é <b>SIMPLES ASSIM</b>.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        className="sessao-site "
        component="section"
        id="container-parceiros"></Container>

      <Container className="sessao-site sessao-parceiro">
        <Grid container maxWidth="xl" className="sessao-site sessao-parceiro">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{
                textAlign: "center",
                pt: 4,
                pb: {xs: 0, lg: 2},
              }}>
              Parceiros QuitaPay
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <SliderPartner />

      <Container
        sx={{ pt: { xs: 0, lg: 4 } }}
        className="sessao-site sessao-depoimentos"
        component="section">
        <Grid container maxWidth="xl">
          <Grid item xs={12}>
            <Typography variant="h1" className="titulo">
              O que nossos parceiros dizem sobre nós:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="depoimento">
              A Quita Pay representa muito para a Credisa. Um produto
              diferenciado em tecnologia e suporte agregando a nossa operação um
              excelente ticket médio e rentabilidade! Eu prezo por parcerias
              leais, inovadoras, e de confiança e todos esses atributos eu
              encontrei com a Quita.
            </Typography>
            <Typography className="parceiro">
              <b>Evandra Neotti</b>
            </Typography>
            <Typography className="parceiro">Ceo Credisa</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="depoimento">
              A parceria com a Quita Pay começou a alguns anos e de lá para cá
              vem se fortalecendo a cada dia que passa. A automação prática e
              voltada a negócios que a empresa desenvolveu e segue desenvolvendo
              se destaca no mercado, a preocupação em ser usual e atender
              verdadeiramente a necessidade do parceiro e do cliente é um grande
              diferencial, sem falar no time de pessoas engajadas em ouvir as
              necessidades do mercado e assim melhorar o processo como um todo,
              sempre em busca do melhor atendimento ao cliente e no sucesso das
              empresas parceiras.
            </Typography>
            <Typography className="parceiro">
              <b>Rodrigo Walker</b>
            </Typography>
            <Typography className="parceiro">
              Gerente Geral CrediAgora
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="depoimento">
              A empresa oferta serviços muito úteis, nos proporcionou propostas
              a clientes que precisavam de valores maiores ou queriam uma taxa
              de juros mais baixa e não encontraram em outras linhas. A
              plataforma intuitiva e fácil de usar e o suporte da empresa é
              ágil, sempre atendendo as nossas necessidades para conclusão das
              operações.
            </Typography>
            <Typography className="parceiro">
              <b>Aliçon Douglas Zaparte</b>
            </Typography>
            <Typography className="parceiro">
              Gerente Operacional CREDIAJAT
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        className="sessao-site parceira-do-seu-negocio"
        component="section">
        <Grid container maxWidth="xl" className="items">
          <Grid item xs={12} md={6}>
            <Typography className="texto">
              <span className="texto-azul">
                Potencializando lucros e crescimento:
              </span>{" "}
              mais que uma oportunidade lucrativa, nossa parceria te ajuda a
              impulsionar o sucesso do seu negócio.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className="imagem-sessao">
            <img
              src="/parceira-do-seu-negocio.png"
              alt="logo parceiro Crediagora"
            />
          </Grid>

          <Grid item xs={12} md={6} className="imagem-sessao">
            <BotaoCadastro className="botao-roxo botao-cadastro">
              VEM SER PARCEIRO QUITA PAY
            </BotaoCadastro>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site solucoes" component="section">
        <Grid container maxWidth="xl">
          <Grid item xs={12} md={4} sx={{ pr: 2, pb: { xs: 4, md: 0 } }}>
            <Typography variant="h1" className="titulo">
              Soluções digitais para parcelamentos e muito mais!
            </Typography>

            <Typography className="subtitulo">
              Oferecemos o parcelamento de boletos e Pix para os seus clientes,
              e você recebe uma comissão atrativa por cada transação realizada.
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} className="right-item">
            <Grid className="border">
              <Typography className="texto">
                <b>Parceria Comissionada</b>: Receba uma comissão atrativa sobre
                cada operação realizada pelos seus clientes, com todo auxílio da
                nossa equipe comercial e de marketing.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="rodape-sessao">
            <Typography className="texto-rodape-sessao">
              Faça parte da nossa missão de promover <b>liberdade financeira</b>
              , recupere histórias e aumente seus ganhos como parceiro da Quita
              Pay.
            </Typography>
          </Grid>

          <Grid item xs={12} className="rodape-sessao">
            <BotaoWhatsapp className="botao-verde botao-atendimento">
              <WhatsApp sx={{ mr: 0.5 }} />
              FALE COM A GENTE
            </BotaoWhatsapp>
          </Grid>
        </Grid>
      </Container>
      <Container
        id="duvidas-frequentes"
        className="sessao-site sessao-faq"
        component="section">
        <Grid container maxWidth="xl" className="banner-faq">
          <Grid item xs={12} md={6} className="left-item">
            <Typography variant="h2" className="titulo">
              Dúvidas Frequentes
            </Typography>

            <Typography variant="h3" className="subtitulo">
              Como podemos te ajudar?
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className="right-item">
            <Grid container>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">Quita Pay é confiável?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Sim! Nossa plataforma oferece{" "}
                      <b>criptografia de ponta a ponta</b> e conta com uma
                      equipe incrível para fornecer suporte em todo o processo.
                      Realizamos a verificação de todos os dados coletados pelo
                      nosso sistema. Prezamos pela segurança tanto dos parceiros
                      quanto dos clientes.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Como funciona a Quita Pay? 
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Nossa missão é democratizar as finanças, ajudar nossos
                      parceiros a recuperar a história de pessoas do Brasil
                      inteiro ao quitar os débitos e, principalmente, evitar que
                      a situação vire uma bola de neve. Tudo isso enquanto
                      oferecemos vantagens exclusivas aos nossos parceiros.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Para quem é a Quita Pay? 
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Com o sistema da Quita Pay, é simples e fácil oferecer as
                      soluções certas para os seus clientes pagarem no ritmo
                      deles. Você ou a sua empresa (Para pessoas físicas ou
                      jurídicas) recebem um comissionamento atrativo e todo o
                      auxílio para cada operação realizada.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Qual solução a Quita Pay oferece? 
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Somos uma fintech especializada no desenvolvimento de
                      soluções para o parcelamento de débitos (boletos, PIX,
                      IPTU, IPVA, etc) no cartão de crédito. Utilizamos o limite
                      do cartão de crédito para permitir pagamentos práticos,
                      liquidando os débitos à vista e assim dando alívio
                      financeiro para quem precisa.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Quais pagamentos a Quita Pay realiza?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Parcelamos boletos de diversos tipos, incluindo contas de
                      luz, água, aluguel, condomínio, prestação de carro,
                      impostos, telefone, mensalidades de faculdade e cursos,
                      mensalidades escolares, plano de saúde, parcelas de
                      empréstimo, entre outros. Além disso, oferecemos pagamento
                      de IPTU e IPVA. E também, parcelamento de PIX para
                      terceiros.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      A Quita Pay tem suporte? 
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Sim, oferecemos suporte aos nossos parceiros, ajudando a
                      esclarecer dúvidas e facilitando a reversão de vendas. A
                      Quita Pay também fornece treinamento gratuito para a
                      equipe do parceiro, garantindo que estejam bem preparados
                      para o sucesso
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Como se tornar parceiro da Quita Pay? 
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Preencha o formulário em nosso site e aguarde o contato do
                      nosso time.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Por que escolher ser parceiro Quita Pay?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Ao se tornar parceiro da Quita Pay, você ou sua empresa
                      podem obter ganhos de várias maneiras. Isso inclui
                      comissões por operação (para cada transação realizada),
                      subcomissionamento (quando você cadastra parceiros abaixo
                      do seu código) e indicações de novos parceiros.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Como é calculado o comissionamento Quita Pay?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      O comissionamento varia de acordo com diferentes faixas, e
                      o parceiro pode escolher a que melhor se adequa à sua
                      operação.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} className="item-faq">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="titulo-item-faq"
                    id="panel1a-header">
                    <Typography variant="h3">
                      Possui algum custo para ser parceiro Quita Pay?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="descricao">
                      Não! Não solicitamos nenhum tipo de pagamento para você
                      operar com o sistema Quita Pay e não definimos metas para
                      você receber o comissionamento.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Rodape></Rodape>
    </>
  );
};

export default Inicio;
