import { Button } from "@mui/material";
import { retornaUrlApp } from "../services/utils";

export default function BotaoCadastro(props) {
  return (
    <Button
      variant="contained"
      onClick={() => window.open(`${retornaUrlApp()}/parceiro/cadastro`)}
      {...props}>
      {props.children}
    </Button>
  );
}
