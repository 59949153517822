import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { direcionaParaApp } from "@/services/utils";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import { isMobile } from "react-device-detect";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import BotaoText from "@/components/BotaoText";
import Logo from "@/components/Logo";
import MenuIcon from "@mui/icons-material/Menu";
import BotaoCadastro from "@/components/BotaoCadastro";
import "./Header.scss";

function Header() {
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const register = () => {
    window.open(`${direcionaParaApp()}/parceiro/cadastro`);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const abrirAction = (page) => {
    navigation(page);
  };

  const entrar = () => {
    direcionaParaApp("/parceiro/login");
  };

  const scrollInPage = (section) => {
    const id = document.getElementById(`${section}`);

    if (!id) {
      abrirAction("/");
    } else {
      id.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <AppBar className="toolbar" sx={{ position: "fixed" }}>
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              color="black"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}>
              <MenuIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <MenuItem
                onClick={() => {
                  scrollInPage("duvidas-frequentes");
                }}>
                <Typography textAlign="center">DÚVIDAS FREQUENTES</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigation("/desenvolvedores");
                }}>
                <Typography textAlign={"center"}>DESENVOLVEDORES</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  register();
                }}>
                <Typography textAlign="center">SEJA PARCEIRO</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  entrar();
                }}>
                <Typography textAlign="center">ENTRAR</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "inherit",
            }}>
            <Logo
              onClick={() => {
                scrollInPage("inicio");
              }}
              style={{ maxWidth: "150px" }}
            />
          </Typography>
          <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
            <Grid item md={3} sx={{ display: "flex", alignItems: "center" }}>
              <Logo
                onClick={() => {
                  scrollInPage("inicio");
                }}
                style={{
                  maxWidth: isMobile ? "90px" : "150px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid item md={9}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}>
                <BotaoText
                  className="botao"
                  onClick={() => {
                    scrollInPage("duvidas-frequentes");
                  }}>
                  DÚVIDAS FREQUENTES
                </BotaoText>
                <BotaoText
                  className="botao"
                  onClick={() => {
                    navigation("/desenvolvedores");
                  }}>
                  DESENVOLVEDORES
                </BotaoText>
                <BotaoCadastro variant="text" className="botao" sx={{ pr: 2 }}>
                  SEJA PARCEIRO
                </BotaoCadastro>
                <BotaoText
                  className="botao entrar"
                  onClick={() => {
                    entrar();
                  }}>
                  ENTRAR
                </BotaoText>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
